import React, { CSSProperties, useState } from 'react';
import { Camotes2,CebuCamotes, PalawanCoron, PalawanElNido } from '../../components/assetMapping/ImgPath';
import Inputs from '../../components/forms/Inputs';
import IconMap from '../../components/assetMapping/IconMap';

import { Dropdown, Space,Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const HomePage: React.FC<any> = ({ setLoadingState }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<string>('Round-trip');
  
  const buttonStyle:CSSProperties = {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: '20px'
  };


  const items: any[]= [
    {
      key: '1',
      label: 'Round-trip',
      value:'round_trip'
    },
    {
      key: '2',
      label: 'One-way',
      value:'one_way'
    },
  ];


  const handleMenuClick = (e: any) => {
    const selected = items.find(item => item.key === e.key);
    if (selected) {
      setSelectedItem(selected.label);
    }
  };
  
  return (
    <>
      <section
        className='flex flex-col'
        style={{
          height: '600px', 
          width: '100%', 
          color: 'white',
          background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${Camotes2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='flex flex-col items-center mt-[8rem] lg:mt-[13rem]'>
          <h1 className='text-[20px] sm:text-[30px] md:text-[40px] font-extrabold'>Traveling to Coron or El Nido? </h1>
          <h4 className='text-[12px] sm:text-[15px] md:text-[30px] font-[400]'>Book your tickets online! </h4>
        </div>
        <div className='flex justify-center grid grid-cols-1 lg:grid-cols-5 gap-4 px-[5%] xs:px-[10%] sm:px-[20%] md:px-[28%] lg:px-[15%] xl:px-[10%]  pt-4'>
          <Dropdown
            className='!text-white'
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: ['1'],
              onClick: handleMenuClick,
            }}
          >
            <Typography.Link>
              <Space>
                {selectedItem}
                <DownOutlined />
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className='w-full flex justify-center '>
          <div className='flex justify-center grid grid-cols-1 lg:grid-cols-5 gap-4 lg:px-[10rem] py-4'>
            <div className='col-span-2'>
              <Inputs type='route-selector' options={undefined} value={undefined} value2={undefined} onChange={undefined} onChange2={undefined}  label={undefined} id="select_route" icon={'FaRoute'} placeholder='Select Origin' placeholder2='Select Destination' />
            </div>
            <div className='col-span-2'>
              {selectedItem == 'Round-trip'? 
                <Inputs format={'YYYY-MM-DD'} type='rangepicker' value={[undefined, undefined]} onChange={undefined} showTime={true} label={undefined} id="select_date" icon={'FaCalendarDay'} /> :
                <Inputs  withValidation onChange={undefined}  value={undefined}  format="HH"  label={undefined} type={'date'} 
              />}
            </div>
            <div className='flex items-start space-x-4'>
              <Inputs type='number' value={undefined} onChange={undefined} label={undefined} id="select_voyages" icon={'IoIosPeople'} className='' />
              <div className="inline-flex text-white w-[165px] h-[51px] bg-[#59B93C] ml-4 justify-center items-center cursor-pointer" 
                style={buttonStyle} >
                <span className="">{IconMap('FaSearch')}</span> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='flex justify-center relative mt-[-4rem]'>
        <div className='flex justify-center grid grid-cols-2 lg:grid-cols-4 gap-5 p-5 bg-[#F6E900]'>
          <div className='flex flex-col justify-center items-center p-3'>{IconMap('BsStars',undefined,undefined,35)}<h3 className='text-[16px] font-bold'>Brand New</h3></div>
          <div className='flex flex-col justify-center items-center p-3'>{IconMap('AiFillDashboard',undefined,undefined,35)}<h3 className='text-[16px] font-bold'>Fastest</h3></div>
          <div className='flex flex-col justify-center items-center p-3'>{IconMap('FaShip',undefined,undefined,35)}<h3 className='text-[16px] font-bold'>Amazing Deck View</h3></div>
          <div className='flex flex-col justify-center items-center p-3'>{IconMap('FaThumbsUp',undefined,undefined,35)}<h3 className='text-[16px] font-bold'>Comfortable Seats</h3></div>
        </div>
      </section>

      <section className='flex flex-col justify-center relative bg-white px-[10%] pt-[10%]'>
        <div className='flex flex-col justify-center items-center lg:mt-[-4rem]'>
          <h2 className='text-[30px] font-extrabold mb-3'>Popular Destinations</h2>
          <h5 className="relative flex flex-row items-center text-[20px] text-[#59B93C] font-semibold mb-3 hover:cursor-pointer group">
            <span>
              View All Destinations
              <hr className="absolute bottom-0 left-0 w-full h-[2px] bg-[#59B93C] transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300"/>
            </span>
            <span className="ml-2 inline-block transform transition-transform duration-300 group-hover:translate-x-2">
              {IconMap('FaArrowRightLong')}
            </span>
          </h5>
        </div>
        <div className='flex justify-center grid grid-cols-1 lg:grid-cols-3 gap-5 '>
          <div
            style={{
              height: '230px',
              width: '100%',
              color: 'white',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${PalawanCoron})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='h-full flex flex-col justify-center items-center'>
              <h2 className='text-white text-[28px] font-bold mb-4 '>Coron, Palawan</h2>
              <div className='w-full px-[40%]'><hr className='w-full border solid border-[#F6E900]'/></div>
              <button className="border border-white text-white rounded-[4px] mt-4 px-4 py-2 hover:bg-white hover:text-black transition ease-in-out duration-300">
                Book Now!
              </button>
            </div>
          </div>
          <div
            style={{
              height: '230px',
              width: '100%',
              color: 'white',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${PalawanElNido})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='h-full flex flex-col justify-center items-center'>
              <h2 className='text-white text-[28px] font-bold mb-4 '>El Nido, Palawan</h2>
              <div className='w-full px-[40%]'><hr className='w-full border solid border-[#F6E900]'/></div>
              <button className="border border-white text-white rounded-[4px] mt-4 px-4 py-2 hover:bg-white hover:text-black transition ease-in-out duration-300">
                Book Now!
              </button>
            </div>
          </div>
          <div
            style={{
              height: '230px',
              width: '100%',
              color: 'white',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CebuCamotes})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='h-full flex flex-col justify-center items-center'>
              <h2 className='text-white text-[28px] font-bold mb-4 '>Camotes, Cebu</h2>
              <div className='w-full px-[40%]'><hr className='w-full border solid border-[#F6E900]'/></div>
              <button className="border border-white text-white rounded-[4px] mt-4 px-4 py-2 hover:bg-white hover:text-black transition ease-in-out duration-300">
                Book Now!
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col justify-center relative mt-[4rem] bg-[#EDEDED] p-6 h-[300px]'>
        <div className='flex flex-col justify-center items-center'>
          <h2 className='text-[30px] font-extrabold mb-3'>Book your next trip with us!</h2>
          <h5 className='flex flex-row items-center text-[20px] font-[400] mb-3'>Here at Jomalia Shipping we take you to the places within your reach, with ease.</h5>
        </div>
        <div className='w-full flex justify-center'>
          <button className="border border-[#59B93C] bg-[#59B93C] text-white rounded-[4px] mt-4 px-4 py-2 hover:bg-[#388221] hover:border-[#388221] transition ease-in-out duration-300">
            View our Schedules and Fares
          </button>
        </div>
      </section>
    </>
  );
};

export default HomePage;
