import { IoSwapHorizontalOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { BsStars } from "react-icons/bs";
import { AiFillDashboard } from "react-icons/ai";
import { FaShip,FaThumbsUp,FaRoute,FaCalendarDay,FaSearch,FaFacebookSquare,FaTwitterSquare,FaBars } from "react-icons/fa";
import { FaArrowRightLong,FaInstagram } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

const IconMap = (iconName: any, classnames = '', iconStyle = {}, size = 20) => {
  // let size = 20;
  let icon = null;
  switch(iconName) {
    
    case 'MdClose': 
      icon = <MdClose className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaBars': 
      icon = <FaBars className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaInstagram': 
      icon = <FaInstagram className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaTwitterSquare': 
      icon = <FaTwitterSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaFacebookSquare': 
      icon = <FaFacebookSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaSearch': 
      icon = <FaSearch className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaArrowRightLong': 
      icon = <FaArrowRightLong className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoIosPeople': 
      icon = <IoIosPeople className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRoute': 
      icon = <FaRoute className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCalendarDay': 
      icon = <FaCalendarDay className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaThumbsUp': 
      icon = <FaThumbsUp className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaShip': 
      icon = <FaShip className={classnames} size={size} style={iconStyle} />
    break;
    case 'AiFillDashboard': 
      icon = <AiFillDashboard className={classnames} size={size} style={iconStyle} />
    break;
    case 'BsStars': 
      icon = <BsStars className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoSwapHorizontalOutline': 
      icon = <IoSwapHorizontalOutline className={classnames} size={size} style={iconStyle} />
    break;
    
  }
  return icon;
};

export default IconMap;