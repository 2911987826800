import React, { useState, useEffect } from 'react';
import { Logo, LogoAlt } from '../assetMapping/ImgPath';
import IconMap from '../assetMapping/IconMap';

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    
    <header className={`fixed top-0 left-0 w-full p-4 transition-colors duration-300 z-10  ${isScrolled || menuOpen ? 'bg-[#2B2B2B] text-black' : 'bg-transparent text-white'}`}>
      <div className="container mx-auto flex justify-between items-center">
        <img className="hidden lg:block" alt="Desktop Logo"  src={LogoAlt} width={230}/>
        <img className="block lg:hidden" alt="Mobile Logo" src={Logo} width={80}/>
        <nav>
          <button className="flex space-x-4 block lg:hidden border border-white bg-white bg-opacity-[15%] text-white rounded p-2" onClick={() => setMenuOpen(prevState => !prevState)}>{menuOpen? IconMap('MdClose'): IconMap('FaBars')}</button>
          <ul className="flex space-x-4 hidden lg:flex">
            <li><a href="#" className={`hover:underline text-white`}>Home</a></li>
            <li><a href="#" className={`hover:underline text-white`}>Schedule & Fares</a></li>
            <li><a href="#" className={`hover:underline text-white`}>About Us</a></li>
            <li><a href="#" className={`hover:underline text-white`}>Contact Us</a></li>
            <li><a href="#" className={`hover:underline text-white`}>Visit Palawan</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <div className='relative'>
      {
        menuOpen && 
        <ul className='fixed w-full bg-[#2B2B2B] p-2 mt-[6rem] z-10 lg:hidden'>
          <li className='p-4'><a href="#" className={`text-[20px] hover:underline text-white`}>Home</a></li>
          <li className='p-4'><a href="#" className={`text-[20px] hover:underline text-white`}>Schedule & Fares</a></li>
          <li className='p-4'><a href="#" className={`text-[20px] hover:underline text-white`}>About Us</a></li>
          <li className='p-4'><a href="#" className={`text-[20px] hover:underline text-white`}>Contact Us</a></li>
          <li className='p-4'><a href="#" className={`text-[20px] hover:underline text-white`}>Visit Palawan</a></li>
        </ul>
      }
    </div>
    </>
    
  );
};

export default Header;
