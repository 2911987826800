var Colors = {
  BasicWhite: "#FFFFFF",
  BorderGray: "#3D3D3D",
  BackgroundGray: '#F8F8F8',
  JomaliaGray1: "#2B2B2B",
  JoaliaGraySubtle: "#585858",
  JomaliaGreen: "#59B93C",
  JomaliaYellow: "#E4DB2D",
  PlaceholderGray: "#888888",
  ticketCompleted: "#4BD0B8",
  ticketBoarded: "#39ADE0",
  ticketCancelled: "#EB6D7B",
  ticketOpen: "#F1BF3E",
  UpdateOrange: "#F1BF3E"
};

export default Colors;