import React, { ChangeEvent,KeyboardEvent } from "react";
import { Select, Space, Tooltip,Button,Upload } from 'antd';
import {Validations} from "./Validations";
import { UploadOutlined } from '@ant-design/icons';
import IconMap from "../assetMapping/IconMap";
import { DatePicker, TimePicker,Radio } from 'antd';
import Colors from "../../../assets/styles/Colors";
const { RangePicker } = DatePicker;

interface InputsProps {
  options?: { value?: any; label?: any; key?:any}[];
  style?: any;
  value: any;
  value2?: any;
  onChange?: any;
  onChange2?: any;
  onKeyDown?: any;
  label?: string;
  id?: any,
  type: string,
  icon?: string,
  placeholder?: string,
  placeholder2?: string,
  required?: boolean,
  readOnly?:boolean,
  tooltip?: any,
  name?: any,
  disabled?: boolean,
  target?: string
  defaultValue?: any,
  format?: any,
  mode?:any,
  picker?: any,
  disabledDate?:any,
  dataId?: any,
  withValidation?: boolean,
  disableAutocomplete?: boolean,
  inputType?: any,
  props?:any,
  loading?:boolean,
  showTime?:boolean,
  maxCount?:number,
  className?:any
}

const Inputs: React.FC<InputsProps> = ({
  target,
  style,
  options,
  value,
  value2,
  onChange,
  onChange2,
  onKeyDown,
  label,
  id,
  type,
  icon,
  placeholder,
  placeholder2,
  required,
  tooltip,
  name,
  disabled,
  readOnly,
  defaultValue,
  format,
  mode,
  picker,
  disabledDate,
  dataId,
  withValidation,
  disableAutocomplete,
  inputType,
  props,
  loading,
  showTime,
  maxCount,
  className
}) => {
  // Define a common onKeyDown event handler
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleSwap = () => {
    const temp = value;
    onChange(value2);
    onChange2(temp);
  };

  const formatPhoneNumber = (phoneNumber: any) => {
    // console.log('phoneNumber ::: ', phoneNumber)
    const numericValue = phoneNumber.replace(/[^\d+]/g, '');

    // console.log('formatted phoneNumber ::: ', numericValue.startsWith('+63') ? numericValue : `+63${numericValue}`)
    return numericValue.startsWith('+63') ? numericValue : `+63${numericValue}`;
  };


  switch(type) {
    case 'route-selector':
    return (
      <>
        <div className={`flex items-center ${className}`} style={style}>
          <div className="flex flex-col w-full">
            <label className="font-semibold flex items-center">
              {label}
              {tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )}
            </label>
            <div className="flex items-center relative">
              <Select
                placeholder={placeholder}
                placement="bottomLeft"
                popupMatchSelectWidth={true}
                showSearch
                optionFilterProp="children"
                onChange={onChange}
                className="w-full h-full focus:outline-none bg-white px-3 py-2 appearance-none"
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                bordered={false}
                defaultValue={value}
                value={value}
                showArrow={false}
                options={options}
                disabled={disabled}
              />
              
              <div className="absolute left-[12rem] md:left-[13rem] z-[9] ">
                {IconMap('IoSwapHorizontalOutline', 'hover:cursor-pointer',{ color: Colors.JomaliaGreen })}
              </div>
              
              <Select
                placeholder={placeholder2}
                placement="bottomLeft"
                popupMatchSelectWidth={true}
                showSearch
                optionFilterProp="children"
                onChange={onChange2}
                className="w-full h-full focus:outline-none bg-white px-3 py-2 appearance-none"
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                bordered={false}
                defaultValue={value}
                value={value2}
                showArrow={false}
                options={options}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </>
    );
    case 'select':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold">{label}</label>
          <div className="flex items-center relative">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Select
                placeholder={label}
                placement="bottomLeft"
                popupMatchSelectWidth={true}
                showSearch
                optionFilterProp="children"
                onChange={onChange}
                className="w-full h-full focus:outline-none bg-white px-3 py-2 appearance-none"
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                bordered={false}
                defaultValue={value}
                value={value}
                showArrow={false}
                options={options}
                disabled={disabled}
              />
            </Space>
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'select-border':
      return (
        <>
          <div className="flex-col flex w-full">
            <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
              {
                tooltip && (
                  <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                    {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                  </Tooltip>
                )
              }
            </label>
            <div className="flex items-center relative border">
              <Space wrap direction="vertical" style={{ width: '100%' }}>
                <Select
                  {...(target && { target })}
                  mode={mode ? mode : null}
                  placeholder={label}
                  placement="bottomLeft"
                  popupMatchSelectWidth={true}
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => onChange(value, id, target)}
                  className={`w-full focus:outline-none bg-white px-3 py-2 appearance-none ${className}`}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  bordered={false}
                  defaultValue={value}
                  value={value}
                  showArrow={false}
                  options={options}
                  aria-required={required}
                  key={""}
                  disabled={disabled}
                  style={style}
                />
              </Space>
              {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
            </div>

            {withValidation && <Validations value={value} type={type} required={required} />}
          </div>
        </>
      );
      break;
    case 'text':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
            {
              tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )
            }
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type="text"
              placeholder={placeholder}
              id={id}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={handleKeyDown} // Add the onKeyDown event handler
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>

          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'email':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
            {
              tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )
            }
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type="email"
              placeholder={placeholder}
              id={id}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={handleKeyDown} // Add the onKeyDown event handler
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>

          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'text-area':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">
            {label} {required ? <em className="text-green">*</em> : ''}
            {tooltip && (
              <Tooltip
                overlayInnerStyle={{ padding: '10px', textAlign: 'center' }}
                title={tooltip}
              >
                {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
              </Tooltip>
            )}
          </label>
          <div className="flex items-center relative">
            <textarea
              // Rest of your props
              id={id}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'phone':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">{label} {required ? (<em className="text-green">*</em>) : ('')}
            {tooltip && (
              <Tooltip overlayInnerStyle={{ padding: '10px', textAlign: 'center' }} title={tooltip}>
                {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
              </Tooltip>
            )}
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type="text"
              placeholder={placeholder}
              id={id}
              name={name}
              value={formatPhoneNumber(value)}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && value === '+63') {
                  e.preventDefault();
                }
              }}
              onKeyPress={(e) => {
                if (!/^\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              maxLength={13}
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>

          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'number':
    return (
      <>
        <div className={`flex-col flex w-full ${className}`}>
          <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
            {
              tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )
            }
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type="number"
              placeholder={placeholder}
              id={id}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={handleKeyDown} // Add the onKeyDown event handler
              required={required}
              pattern="[0-9.]+"
              className="w-full focus:outline-none border bg-white p-3"
              disabled={disabled}
              style={style}
              min={0}
              onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    break;
    case 'date':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold">{label}</label>
          <div className="flex items-center relative">
            <DatePicker 
              {...(target && { target })}
              {...(picker && { picker })}
              id={id}
              onChange={onChange}
              value={value}
              format={format ? "YYYY-MM-DD HH:mm" : format}
              className="w-full focus:outline-none border-gray-100 rounded-none relative p-3"
              disabled={disabled}
              disabledDate={disabledDate}
              showTime={{ format: 'HH:mm' }}
            />
            {/* <DatePicker
              selected={value}
              onChange={onChange}
              dateFormat="EEEE, MMMM d, yyyy"
              placeholderText="Select a date"
              className="w-full focus:outline-none relative p-3"
              popperPlacement="top-end"
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [-140, 10],
                  },
                }
              ]}
            /> */}
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    break;
    case 'timepicker':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold">{label}</label>
          <div className="flex items-center relative">
            {/* <DatePicker 
              onChange={onChange}
              value={value}
              format="dddd, MMMM DD, YYYY"
              className="w-full focus:outline-none border-gray-100 rounded-none relative p-3"
            /> */}
            <TimePicker 
              onChange={onChange}
              value={value}
              format={format}
              disabledDate={disabledDate}
              className="w-full focus:outline-none border-gray-100 rounded-none relative p-3"
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    break;
    case 'rangepicker':
      return (
        <>
          <div className={`flex-col flex w-full ${className}`}>
            <label className="font-semibold">{label}</label>
            <div className="flex items-center relative">
              <RangePicker
                allowEmpty={[true, true]}
                showTime={showTime ? { format: "h:mm A" } : undefined}
                format={format != undefined ? format : "YYYY-MM-DD HH:mm:ss"}
                defaultValue={value}
                onCalendarChange={onChange}
                onChange={onChange}
                className={`w-full focus:outline-none border-gray-200 rounded-none relative p-3`}
                disabledDate={disabledDate}
                aria-required={required}
             />
            </div>
            {withValidation && <Validations value={value} type={type} required={required} />}
          </div>
        </>
      );
    break;
    case 'switch':
      return (
        <>
          <div className="flex-col flex w-full">
            {label && <label className="font-semibold">{label}</label>}
            <div className="flex items-center relative p-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input data-id={dataId} id={id} type="checkbox" value={value} checked={value} onChange={onChange} className="sr-only peer" required={required} />
                <div className="p-3 w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green"></div>
              </label>
            </div>
            {withValidation && <Validations value={value} type={type} required={required} />}
          </div>
        </>
      );
    break;
    case 'password':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
            {
              tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )
            }
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type="password"
              placeholder={placeholder}
              id={id}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={handleKeyDown} // Add the onKeyDown event handler
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
              autoComplete={disableAutocomplete ? "off" : "on"}
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'pin':
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold flex items-center">{label} { required ? ( <em className="text-green">*</em> ) : ('')}
            {
              tooltip && (
                <Tooltip overlayInnerStyle={{padding: '10px', textAlign: 'center'}} title={tooltip}>
                  {IconMap('FaInfoCircle', 'text-xs text-gray-500 ml-2', {}, 12)}
                </Tooltip>
              )
            }
          </label>
          <div className="flex items-center relative">
            <input
              {...(target && { target })}
              type={inputType}
              placeholder={placeholder}
              id={id}
              name={`password_${Math.random()}`}
              pattern="[0-9]{4}"
              value={value}
              onChange={(e) => onChange && onChange(e, name)}
              onKeyDown={handleKeyDown} // Add the onKeyDown event handler
              required={required}
              className="w-full focus:outline-none border bg-white p-3 appearance-none"
              disabled={disabled}
              style={style}
              autoComplete="new-password"
            />
            {IconMap(icon, 'absolute right-3 text-green',{ color: Colors.JomaliaGreen })}
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'radio':
      if (!options) {
        return null; 
      }
    return (
      <>
        <div className="flex-col flex w-full">
          <label className="font-semibold">{label}</label>
          <div className="flex items-center relative">
            <Radio.Group
              onChange={onChange}
              value={value}
              className="flex flex-wrap"
            >
              {options.map((option, index) => (
                <Radio key={option.key} value={option.value} 
                className={`mb-2 ${index % 4 === 0 ? 'ml-0' : ''}`}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          {withValidation && <Validations value={value} type={type} required={required} />}
        </div>
      </>
    );
    case 'checkbox':
      return (
        <>
          <div className="flex-col flex w-full">
            <label className="font-semibold">{label}</label>
            <div className="flex items-center relative p-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  id={id}
                  {...(target && { target })}
                  type="checkbox"
                  checked={value}
                  onChange={onChange}
                  className="sr-only peer"
                  required={required}
                  readOnly={readOnly}
                />
                <div className="p-3 w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green"></div>
              </label>
            </div>
            {withValidation && <Validations value={value} type={type} required={required} />}
          </div>
        </>
      );

      case 'filter':
        return (
          <>
            <div className="flex-col flex">
              <div className="flex items-center relative p-3">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    id={id}
                    {...(target && { target })}
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                    className="sr-only peer"
                    required={required}
                    readOnly={readOnly}
                  />
                  <div className="p-3 w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green"></div>
                  <span className="ml-2">{label}</span>
                </label>
              </div>
              {withValidation && <Validations value={value} type={type} required={required} />}
            </div>
          </>
        );     
        case 'upload':
          return (
            <>
              <Upload {...props} maxCount={maxCount}>
                <Button
                  icon={<UploadOutlined />}
                  type="primary"
                  disabled={disabled}
                  loading={loading}
                  style={{ marginTop: 16, backgroundColor: '#59B93C' }}
                >
                  {loading ? 'Uploading' : 'Upload'}
                </Button>
              </Upload>
            </>
          );
    default:
    return (<></>);
    break;
  }
  
};

export default Inputs;