import { useEffect, useRef, useState } from "react";
import { Payments } from "../assetMapping/ImgPath";
import IconMap from "../assetMapping/IconMap";

const Footer: React.FC<any> = ({ })=>{

  return(
    <>
      <section className="bg-[#2B2B2B]">
        <div className='h-full flex justify-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 px-[5rem] py-[4rem]'>
          <div className="h-full flex flex-col justify-center">
            <div className="flex flex-col justify-center items-center">
              <h2 className="font-[700] text-[16px] text-[#F6E900]">Contact Us</h2>
              <h4 className="font-[400] text-[14px] text-white"> (032) 346 0421</h4>
            </div>
            <div className="mt-6 flex flex-col justify-center items-center">
              <h2 className="font-[700] text-[16px] text-[#F6E900]">Email</h2>
              <h4 className="font-[400] text-[14px] text-white"> ticketing@jomaliashipping.com</h4>
            </div>
          </div>
          <div className="h-full flex flex-col justify-center items-center">
            <h2 className="font-[700] text-[16px] text-white">Contact Us</h2>
            <ul className="mt-4">
              <li className="font-[300] text-[16px] text-white">Purpose</li>
              <li className="font-[300] text-[16px] text-white">Vision and Mission</li>
              <li className="font-[300] text-[16px] text-white">Core Values</li>
            </ul>
          </div>
          <div className="h-full flex flex-col justify-center items-center">
            <h2 className="font-[700] text-[16px] text-white">Support</h2>
            <ul className="mt-4">
              <li className="font-[300] text-[16px] text-white">Customer Support</li>
              <li className="font-[300] text-[16px] text-white">Privacy & Policy</li>
              <li className="font-[300] text-[16px] text-white">Contact Channels</li>
            </ul>
          </div>
          <div className="h-full flex flex-col justify-center  items-center">
            <h2 className="font-[700] text-[16px] text-white">Pay Safely With Us</h2>
            <p className="mt-4 font-[300] text-[16px] text-white">The payment is encrypted and transmitted securely with an SSL protocol.</p>
            <img className="mt-4" src={Payments}/>
          </div>
        </div>
      </section>

      <section className="bg-[#161616]">
        <div className='h-full flex items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-5 px-[10%] py-3'>
          <div className="flex flex-col items-center md:items-start col-span-3"> 
            <p className="font-[400] text-[12px] text-[#9F9F9F]">© 2020 Jomalia Shipping Corporation. All Rights Reserved.</p>
          </div>
          <div className="flex flex-col items-center md:items-end">
            <p className="flex flex-row font-[400] text-[12px] text-[#9F9F9F]">Follow us on <span className="flex flex-row">{IconMap('FaFacebookSquare','ml-2')} {IconMap('FaTwitterSquare','ml-2')} {IconMap('FaInstagram','ml-2')}</span></p>
          </div>
        </div>
      </section>
    </>
  )
  
}


export default Footer;